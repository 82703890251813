import { FontFace } from '@Themes';

const generateFontSrc = (fileName: string, baseUrl: string) => {
  if (fileName.includes('local')) {
    return fileName;
  }

  return `url(${baseUrl}${fileName}) format('woff2')`;
};

export const generateFontFace = (fontFace: FontFace, baseUrl: string) => `
  @font-face {
    font-family: ${JSON.stringify(fontFace.family)};
    ${fontFace.style ? `font-style: ${fontFace.style};` : ''}
    ${fontFace.weight ? `font-weight: ${fontFace.weight};` : ''}
    ${fontFace.display ? `font-display: ${fontFace.display};` : ''}
    ${fontFace.ascentOverride ? `ascent-override: ${fontFace.ascentOverride};` : ''}
    ${fontFace.sizeAdjust ? `size-adjust: ${fontFace.sizeAdjust};` : ''}
    src: ${generateFontSrc(fontFace.fileName, baseUrl)};
    ${fontFace.unicodeRange ? `unicode-range: ${fontFace.unicodeRange.join(', ')};` : ''}
  }
`;
