import { getGap } from './getGap';
import { ThemeDefinition } from '@Themes';
import { SpacerSizes } from '@Tokens';

export const getFlexBasis = (
  itemsInRow: number,
  gap: keyof SpacerSizes | 0,
) => (theme: ThemeDefinition) => {
  const pixels = getGap(itemsInRow, gap, theme);

  return `calc(100% / ${itemsInRow} - ${pixels}px)`;
};
