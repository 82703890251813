import React, {
  createContext, useContext, useEffect, useState,
} from 'react';

import { debounce } from '../utils/debounce';

const isClient = typeof window !== 'undefined';

export const WindowSizeContext = createContext({
  width: Infinity,
  height: Infinity,
});

export const WindowSizeProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [ state, setState ] = useState(() => ({
    width: isClient ? window.innerWidth : Infinity,
    height: isClient ? window.innerHeight : Infinity,
  }));

  useEffect(() => {
    const handler = debounce(() => {
      setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 100);

    window.addEventListener('resize', handler, { passive: true });

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return (
    <WindowSizeContext.Provider value={state}>
      {children}
    </WindowSizeContext.Provider>
  );
};

export const useWindowSize = () => useContext(WindowSizeContext);
