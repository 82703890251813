import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';

type UseInViewOptions = IntersectionObserverInit & {
  triggerOnlyOnce?: boolean;
  disabled?: boolean;
};

/**
 * Uses IntersectionObserver API to notify when an element enters or leaves the viewport.
 *
 * @example
 *  const ref = useInView((isInView) => console.log('element is in view:', isInView));
 *  return <div ref={ref} />
 */
export const useInView = (
  callback: (isInView: boolean, entry: IntersectionObserverEntry) => void,
  options: UseInViewOptions = {},
) => {
  // Do not initialise on SSR
  if (typeof window === 'undefined') {
    return null;
  }

  const {
    triggerOnlyOnce,
    disabled = false,
    ...intersectionObserverOptions
  } = options;

  const elementRef = useRef<Element | null>(null);

  const observer = useMemo(() => new IntersectionObserver(
    (entries) => entries.forEach((entry) => {
      callback(entry.isIntersecting, entry);

      // Unsubscribe from observer once an element is in view
      if (triggerOnlyOnce && entry.isIntersecting) {
        observer.unobserve(entry.target);
      }
    }),
    intersectionObserverOptions,
  ), [ callback ]);

  // Unsubscribe from observer when unmounting
  useEffect(() => () => {
    if (elementRef.current) {
      observer.unobserve(elementRef.current);
    }
  }, []);

  // This is a callback ref (https://reactjs.org/docs/refs-and-the-dom.html#callback-refs).
  // When passing this in as a `ref` prop to an element this will be called when the element
  // mounts/unmounts. When mounting, the HTML element will be passed in as argument, so we can
  // initialise the observer. When unmounting, it passes in `null` so we unsubscribe the observer.
  return useCallback((element: Element | null) => {
    if (disabled) {
      return;
    }

    // On unmount - unsubscribe the observer
    if (!element) {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }

      return;
    }

    // On mount - initialise observer
    elementRef.current = element;
    observer.observe(element);
  }, [ callback ]);
};
