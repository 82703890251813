import { BorderWidths, ThemeDefinition } from '@Themes';
import { SpacerSizes } from '@Tokens';

export const negativeSpace = (token: keyof SpacerSizes) => (theme: Pick<ThemeDefinition, 'space'>) => {
  const value = theme.space[token];

  return -value;
};

export const negativeBorder = (token: keyof BorderWidths) => (theme: Pick<ThemeDefinition, 'borderWidths'>) => {
  const value = theme.borderWidths[token];

  return -value;
};
