import { Global, css, useTheme } from '@emotion/react';
import React, { Fragment } from 'react';

import { reset } from '../css-reset';
import { usePublicPath } from '../providers/PublicPathProvider';
import { generateFontFace } from '../utils/fonts';

const GlobalStyle = () => {
  const theme = useTheme();
  const publicPath = usePublicPath();

  return (
    <Global
      styles={css`
      ${reset}

      ${theme.fontFaces.map(
        (fontFace) => generateFontFace(fontFace, publicPath),
      )}

      html, body {
        font-family: ${theme.fonts.body};
        background-color: ${theme.colors.backgroundWhite};
        color: ${theme.colors.textDefault};
        line-height: 1.4;
        overflow-anchor: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: ${theme.fonts.heading};
        font-weight: 400;
        color: ${theme.colors.textDefault};
      }

      * {
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
        touch-action: manipulation;
      }

      :focus {
        outline: 3px auto ${theme.colors.textPrimary};
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
        outline-width: 3px;
      }

      input[type=range] {
        width: 100%;
        background: transparent;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }

      a {
        &,
        &:active,
        &:visited {
          color: ${theme.colors.textPrimarydark};
        }

        &:hover {
          color: ${theme.colors.textPrimarydark};
        }
      }

      select {
        font-size: 100%;
        option:disabled {
          color: ${theme.colors.interactiveDisabledlight};
        }
      }

      select, button {
        margin: 0;
        padding: 0;
        border-radius: 0;
        font-family: ${theme.fonts.body};
        line-height: ${theme.typography.label.medium.lineHeight};
        color: ${theme.colors.textDefault};
        border-radius: 0;
        cursor: pointer;
      }

      button {
        font-size: ${theme.typography.label.medium.fontSize}px;
        line-height: ${theme.typography.label.medium.lineHeight};
        border: none;
        background: none;
        cursor: pointer;

        &:disabled {
          cursor: unset;
        }
      }

      em, strong {
        font-weight: 700;
      }

      i {
        font-style: italic;
      }

      svg {
        fill: currentColor;
      }

      .hide-scrollbars {
        scrollbar-width: none; // Firefox
        -ms-overflow-style: none; // IE and Edge
      }

      .hide-scrollbars::-webkit-scrollbar {
        // Chrome, Safari and Opera
        display: none;
      }

      // Disable animations when reduced motion is preferred
      @media screen and (prefers-reduced-motion: reduce) {
        *, *::before, *::after {
          animation: none !important;
          transition: none !important;
        }
      }
    `}
    />
  );
};

export const GlobalStyles: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Fragment>
    <GlobalStyle />
    {children}
  </Fragment>
);
