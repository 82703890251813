// eslint-disable-next-line no-restricted-globals
export class URLQueryParams extends URLSearchParams {
  // eslint-disable-next-line no-restricted-globals
  constructor(init?: string[][] | Record<string, string> | string | URLSearchParams) {
    super(init);
    Object.setPrototypeOf(this, URLQueryParams.prototype);
  }

  toString(): string {
    const encodedParams: string[] = [];
    this.forEach((value: string, key: string) => {
      encodedParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    });

    return encodedParams.join('&');
  }

  toObject(): Record<string, string> {
    const obj: Record<string, string> = {};
    this.forEach((value: string, key: string) => {
      obj[key] = value;
    });

    return obj;
  }
}
