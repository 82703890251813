import { keyframes } from '@emotion/react';
import React from 'react';

import { ComponentProps } from '@Components';

export interface SkeletonProps extends ComponentProps {
}

const waveKeyframes = keyframes`
 0% {
    transform: translateX(-100%);
  }
  50% {
    /* +0.5s of delay between each loop */
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const Skeleton: React.FC<React.PropsWithChildren<SkeletonProps>> = ({ className }) => (
  <span
    className={className}
    data-id="skeleton"
    sx={{
      height: '100%',
      position: 'relative',
      overflow: 'hidden',
      display: 'block',
      backgroundColor: '#E6E3DC',
      '&::after': {
        animation: `${waveKeyframes} 2s linear 0.5s infinite`,
        // eslint-disable-next-line max-len
        background: ({ colors }) => `linear-gradient(90deg, transparent, ${colors.backgroundLight}, transparent)`,
        content: "''",
        position: 'absolute',
        transform: 'translateX(-100%)', /* Avoid flash during server-side hydration */
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
      },
    }}
  >
    &zwnj;
  </span>
);
