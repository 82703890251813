import React, {
  createContext, Fragment, useContext,
} from 'react';

export interface HelmetComponentValue {
  HelmetComponent: React.FC<React.PropsWithChildren<unknown>>;
}

export const HelmetComponentContext = createContext<HelmetComponentValue>({
  HelmetComponent: () => <Fragment />,
});

interface HelmetComponentProviderProps {
  helmetComponent: React.FC<React.PropsWithChildren<unknown>>;
}

export const HelmetComponentProvider = ({
  helmetComponent,
  children,
}: React.PropsWithChildren<HelmetComponentProviderProps>) => (
  <HelmetComponentContext.Provider
    value={{ HelmetComponent: helmetComponent }}
  >
    {children}
  </HelmetComponentContext.Provider>
);

export const useHelmetComponentContext = () => useContext(HelmetComponentContext);
