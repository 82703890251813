import React, { createContext, useContext } from 'react';

export const PublicPathProviderContext = createContext<string>('/');

export interface PublicPathProviderProps {
  publicPath?: string;
}

export const PublicPathProvider: React.FC<React.PropsWithChildren<PublicPathProviderProps>> = ({
  publicPath = '/',
  children,
}) => (
  <PublicPathProviderContext.Provider value={publicPath}>
    {children}
  </PublicPathProviderContext.Provider>
);

export const usePublicPath = () => useContext(PublicPathProviderContext);
