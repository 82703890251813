import React, {
  createContext, Fragment, useContext,
  type PropsWithChildren, type RefAttributes,
} from 'react';
import type { LinkProps } from 'react-router-dom';

export interface RouterLinkValue {
  Link: React.FC<PropsWithChildren<LinkProps & RefAttributes<any>>>;
  /**
   * Method to update the page address using the client side router.
   * Compatible with react-router-dom 5.x.x's `useHistory().push` method and react-router-dom 6.x.x's
   * `useNavigate()` `navigate` method.
   *
   * historyPush is expected to internally adjust the href to be appropriate to the current site.
   */
  historyPush: (to: string) => void;
  /**
   * Hook to make the given href appropriate to the current site.
   * Use `useHref` from react-router-dom in apps on version 6.0.0 or later of ReactRouter.
   *
   * # Usage advice
   *
   * * Do not call repeatedly. Do not write code that simplifies to `useHref(useHref(originalUrl))`.
   * * Use only at the point that the application Javascript hands off to the browser. For example
   *   `window.location.href = useHref(originalUrl)` or `<a href={useHref(originalUrl)}>`.
   */
  useHref: (to: string) => string;
}

export const RouterLinkContext = createContext<RouterLinkValue>({
  Link: Fragment,
  historyPush: () => undefined,
  useHref: (to) => to,
});

interface RouterLinkProviderProps extends RouterLinkValue { }

export const RouterLinkProvider: React.FC<React.PropsWithChildren<RouterLinkProviderProps>> = ({
  Link,
  historyPush,
  children,
  useHref,
}) => (
  <RouterLinkContext.Provider
    value={{ Link, historyPush, useHref }}
  >
    {children}
  </RouterLinkContext.Provider>
);

export const useRouterLinkContext = () => useContext(RouterLinkContext);
