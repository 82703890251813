import { ThemeDefinition } from '@Themes';
import { SpacerSizes } from '@Tokens';

export const getGap = (
  itemsInRow: number,
  gap: keyof SpacerSizes | 0,
  theme: ThemeDefinition,
) => {
  const gapNumber = typeof gap === 'string'
    ? parseInt(String(theme.space[gap]), 10)
    : gap;
  const pixels = Math.ceil(gapNumber - gapNumber / itemsInRow);

  return pixels;
};
